import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/new-design/Layout"
// import { HeartIcon } from "@heroicons/react/outline"
// import SanityBlockContent from "@sanity/block-content-to-react"
// import { GatsbyImage } from "gatsby-plugin-image"
// import { Fragment } from "react"
// import Booking from "../components/template/Booking"
import {
  // CenteredDescCard,
  // CenteredHeadCard,
  Cards,
  // ImgCard,
  // Hero,
} from "../components/template"
import Seo from "../components/seo"
import Title from "../components/sections/title"
import HeadSection from "../components/new-design/HeadSection"
export default function Page({
  location,
  // pageContext: { data },
  data: {
    allSanityBoat: { nodes: boats },
  },
}) {
  // console.log(data)
  // const metaImage = data.headerImage
  //   ? {
  //       src: data.headerImage.asset?.url,
  //       width: data.headerImage.asset?.metadata?.dimensions?.width,
  //       height: data.headerImage.asset?.metadata?.dimensions?.height,
  //     }
  //   : null
  return (
    <Layout>
      <Seo
        template
        // image={metaImage}
        title="Boats"
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      {/* <Title title="Boats" /> */}
      <HeadSection title="Boats" subtitle="A Selection of our finest boats" />
      {/* <CenteredHeadCard data={data} />
      {!data?.headerImage ? <Fragment /> : <ImgCard data={data} />}
      <CenteredDescCard data={data} /> */}
      {/* <Hero property={data} /> */}
      <Cards className="py-12" template link="/boats/" list={boats} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityBoat(sort: { order: ASC, fields: order }) {
      nodes {
        name
        slug {
          _key
          _type
          current
        }
        highPrice
        lowPrice
        length
        cardImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
